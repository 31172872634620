#project_info {
  .row1 {
    margin-top: 40px;
    margin-bottom: 40px;
    
    span.title {
      font-family: 'Noto Sans KR';
      font-size: 17px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.65;
      letter-spacing: normal;
      color: #1d2129;
      margin-bottom: 26px;
      display: block;
    }
    
    .info_wrapper {
      display: grid;
      grid-template-columns: 50% 50%;
    }
    
    .info_item {
      box-shadow: inset 0 -1px 0 0 #e6eaed;
      position: relative;
      width: 97%;
      height: 44px;
      display: flex;
      align-items: center;
      
      span.info_title {
        display: inline-block;
        width: 120px;
        font-family: 'Noto Sans KR';
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.84);
      }
      
      span.info_content {
        font-family: 'Noto Sans KR';
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: #90949c;
        display: inline-block;
        width: 469px;
      }

      span.ellipse {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .wrapper2 {
          display: flex;
          flex-direction: column;
          margin-right: 50px;

          span {
            font-family: 'Noto Sans KR';
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: -0.3px;
            color: rgba(0, 0, 0, 0.84);
          }

          div.rect_back {
            width: 40px;
            height: 10px;
            opacity: 0.65;
            border-radius: 2px;
            background-color: #dadce0;
            margin-top: 12px;

            span.low {
              width: 8px;
              height: 10px;
              border-radius: 2px;
              background-color: #ff6666;
              display: block;
            }
            span.mid {
              width: 21px;
              height: 10px;
              border-radius: 2px;
              background-color: #ffb500;
              display: block;
            }
            span.high {
              width: 40px;
              height: 10px;
              border-radius: 2px;
              background-color: #00c4c4;
              display: block;
            }
          }
        }
        .editable {
          cursor: pointer;
        }

        button.btn_change {
          height: 36px;
          width: 48px;
          font-family: 'Noto Sans KR';
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.46;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          padding: 0;
        }
      }
    }
    
    .right {
      left: 3%;
    }
  }
  
  .row2 {
    margin-top: 40px;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: 51.5% 48.5%;

    .left {
      span.title {
        font-family: 'Noto Sans KR';
        font-size: 17px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.65;
        letter-spacing: normal;
        color: #1d2129;
        margin-bottom: 16px;
        display: block;
        
        em {
          margin-left: 20px;
          font-weight: normal;
          font-size: 14px;
        }
      }

      .wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .wrapper2 {
          display: flex;
          flex-direction: column;
          margin-right: 50px;
          height: 64px;

          span {
            font-family: 'Noto Sans KR';
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: -0.3px;
            color: rgba(0, 0, 0, 0.84);
            margin-bottom: 8px;
          }

          div.rect_back {
            width: 40px;
            height: 10px;
            opacity: 0.65;
            border-radius: 2px;
            background-color: #dadce0;
            margin-top: 12px;

            span.low {
              width: 8px;
              height: 10px;
              border-radius: 2px;
              background-color: #ff6666;
              display: block;
            }
            span.mid {
              width: 21px;
              height: 10px;
              border-radius: 2px;
              background-color: #ffb500;
              display: block;
            }
            span.high {
              width: 40px;
              height: 10px;
              border-radius: 2px;
              background-color: #00c4c4;
              display: block;
            }
          }
        }
      }
    }

    .right {
      span.title {
        font-family: 'Noto Sans KR';
        font-size: 17px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.65;
        letter-spacing: normal;
        color: #1d2129;
        margin-bottom: 16px;
        display: block;
      }

      .wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-end;

        .wrapper2 {
          display: flex;
          flex-direction: column;
          margin-right: 50px;
          height: 64px;

          span {
            font-family: 'Noto Sans KR';
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.6;
            letter-spacing: -0.3px;
            color: rgba(0, 0, 0, 0.84);
            margin-bottom: 8px;
          }

          div.rect_back {
            width: 40px;
            height: 10px;
            opacity: 0.65;
            border-radius: 2px;
            background-color: #dadce0;
            margin-top: 12px;

            span.low {
              width: 8px;
              height: 10px;
              border-radius: 2px;
              background-color: #ff6666;
              display: block;
            }
            span.mid {
              width: 21px;
              height: 10px;
              border-radius: 2px;
              background-color: #ffb500;
              display: block;
            }
            span.high {
              width: 40px;
              height: 10px;
              border-radius: 2px;
              background-color: #00c4c4;
              display: block;
            }
          }
        }
        
        .editable {
          cursor: pointer;
        }

        button.btn_change {
          height: 36px;
          width: 48px;
          font-family: 'Noto Sans KR';
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2.46;
          letter-spacing: normal;
          text-align: center;
          color: #ffffff;
          padding: 0;
        }
      }
    }
  }

  .row3 {
    margin-top: 40px;
    // margin-bottom: 40px;
    
    span.title {
      font-family: 'Noto Sans KR';
      font-size: 17px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.65;
      letter-spacing: normal;
      color: #1d2129;
      margin-bottom: 16px;
      display: block;
    }

    .wrapper {
      // height: 450px;
      // overflow-y: auto;
      // overflow-x: hidden;
    }
  }
}