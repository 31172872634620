.balloon{
    z-index: 9;
    position: absolute;
    top : 20px;
    right: 50%;
    width: max-content;
    max-width: 350px;
    height: auto !important;
    overflow: visible !important;
    white-space: normal !important;
    font-size: 12px !important;
    line-height: 15px !important;
    padding: 10px;
    border-radius: 5px;
    word-break: break-all;
    background: #3c3c3c;
    color: #fff;
    transition: all 0.4s cubic-bezier(0.25,0.8,0.25,1);
    transition-duration: 150ms;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.9;
    transform: translate(50%, 0%);
}