.shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    z-index: 999;

    .dialog_wrap {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 80px;
        left: 50%;
        padding: 36px 64px 36px 64px;
        min-width: 1344px;
        height: auto;
        background-color: #fff;
        transform: translate(-50%, 0%);

        .dialog_close {
            flex: 0 0 24px;
            margin-bottom: 12px;
            width: 100%;
            height: 24px;
            text-align: right;
            right: -32px;
            position: relative;

            i { cursor: pointer; }
        }
        .dialog_pid {
            flex: 0 0 auto;
            margin-bottom: 0px;

            span {
                font-family: 'Noto Sans KR';
                font-size: 13px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.85;
                letter-spacing: -0.3px;
                color: rgba(0, 0, 0, 0.3);
            }
        }

        .dialog_title {
            flex: 0 0 auto;
            margin-bottom: 50px;

            span {
                font-family: 'Noto Sans KR';
                font-size: 28px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.29;
                letter-spacing: -0.6px;
                color: rgba(0, 0, 0, 0.84);
            }
        }

        .dialog_content {
            flex: 1 1 auto;
            // margin-bottom: 40px;

            .tab_wrap {
                box-shadow: inset 0 -1px 0 0 #dadce0;
                margin-bottom: 25px;
            
                .tab_item {
                    font-family: 'Noto Sans KR';
                    font-size: 15px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.6;
                    letter-spacing: normal;
                    text-align: center;
                    color: #60656a;

                    padding: 15px 0;
                    margin-right: 25px;
            
                    &.active {
                        font-size: 15px;
                        font-weight: bold;
                        color: #00a2a2;
                        border-bottom: 3px solid #00b2b2;
                    }
                }

            }
        }

        .dialog_button {
            flex: 0 0 48px;
            padding-right: 56px;
            text-align: right;

            button:not(:first-child) { margin-left: 8px; }
        }
    }

    .tab1 {
        height: 80vh;
        padding-bottom: 68px;
        overflow-y: auto;
    }

    .tab2 {
        height: 84vh;
        padding-bottom: 68px;
        overflow-y: auto;
    }
}