.title_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 1124px;
  align-items: center;

  .left {
    // 페이지 제목
    .page_title{
      margin-bottom: 28px;
      font-size: 28px;
      font-weight: bold;
    }
  }

  .right {
    input {
      width: 343px;
      font-family: 'Noto Sans KR';
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
    }
  }
}

.filter_wrapper {
  min-width: 1124px;
  height: 206px;
  border: solid 1px #e6eaed;
  margin-bottom: 40px;
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  background-color: #fafbfc;

  display: flex;
  //grid-template-columns: 100%;
  flex-direction: column;

  .date_wrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
    label.title {
      min-width: 111px;
      font-family: 'Noto Sans KR';
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.2px;
      color: rgba(0, 0, 0, 0.84);
      margin-left: 24px;
      margin-right: 26px;
      margin-bottom: 0px;
    }
    .select_box {
      width: 88px;
      margin-right: 8px;
      padding-left: 5px;
    }
    .date_range_input_wrap {
      display: flex;
      align-items: center;

      .range_ico {
        padding: 0px 8px;
        font-size: 18px;
      }

      // input[type=date]::-webkit-calendar-picker-indicator,
      input[type=date]::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
      }
    }
  }

  .type_wrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
    label.title {
      min-width: 111px;
      font-family: 'Noto Sans KR';
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.2px;
      color: rgba(0, 0, 0, 0.84);
      margin-left: 24px;
      margin-right: 26px;
      margin-bottom: 0px;
    }
    .checkbox_wrap {
      display: flex;
      label {
        margin: 0 15px 0 0;
        width: auto !important;

        span {
          font-family: 'Noto Sans KR';
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.45;
          letter-spacing: -0.2px;
          color: rgba(0, 0, 0, 0.84);
        }
        span:after {
          font-size: 12px;
        }
      }
    }
  }

  .detail_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
    .left {
      display: flex;
      flex-direction: row;
      label.title {
        min-width: 111px;
        font-family: 'Noto Sans KR';
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: -0.2px;
        color: rgba(0, 0, 0, 0.84);
        margin-left: 24px;
        margin-right: 26px;
        margin-bottom: 0px;
      }
      .checkbox_wrap {
        display: flex;
        label {
          margin: 0 15px 0 0;
          width: auto !important;

          span {
            font-family: 'Noto Sans KR';
            font-size: 15px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.45;
            letter-spacing: -0.2px;
            color: rgba(0, 0, 0, 0.84);
          }
          span:after {
            font-size: 12px;
          }
        }
      }
    }
    .right {
      button {
        width: 88px;
        height: 36px;
        font-family: 'Noto Sans KR';
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        // line-height: 2.4;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
      }
    }
  }

  .status_wrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 14px;
    label.title {
      min-width: 111px;
      font-family: 'Noto Sans KR';
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.2px;
      color: rgba(0, 0, 0, 0.84);
      margin-left: 24px;
      margin-right: 26px;
      margin-bottom: 0px;
    }
    .checkbox_wrap {
      display: flex;
      label {
        margin: 0 15px 0 0;
        width: auto !important;

        span {
          font-family: 'Noto Sans KR';
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.45;
          letter-spacing: -0.2px;
          color: rgba(0, 0, 0, 0.84);
        }

        span:after {
          font-size: 12px;
        }
      }
    }
  }

  .btn_wrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: 32px;
    button {
      width: 88px;
      height: 36px;
      font-family: 'Noto Sans KR';
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      // line-height: 2.4;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
  }
}

.view_wrapper {
  display: flex;
  justify-content: flex-end;
  .right {
    .filter_row{
      display: flex;
      align-items: center;
      margin-bottom: 17px;
      label{
        width: 80px;
        margin-right: 8px;
        margin-bottom: 3px;
        font-size: 13px;
        font-weight: bold;
      }
    }

    .search_box{
      justify-content: flex-end;
      .seach_input_wrap{
        display: flex;
        align-items: center;
        .select_box{
          margin-right: 0px;
          select{
            padding: 1px 32px 1px 12px;
          }
        }
        .input_box{
          margin-right: 8px;
        }
      }
    }
    
    .approval_checkbox{
      padding: 7px 0;
      .approval_checkbox_wrap{
        display: flex;
        align-items: center;
        label{
          width: 80px;
        }
        span{
          font-weight: normal;
        }
      }
    }
    
    .advertiser_checkbox{
      display: flex;
      padding: 7px 0;
      margin-right: 0px;
      .float_box{
        display: flex;
        &:first-child{
          margin-right: 116px;
        }
        .checkbox_wrap{
          display: flex;
          label{
            width: 80px;
            &.width_item{
              width: 110px;
            }
          }
    
          span{
            font-weight: normal;
            width: unset;
            word-break: break-all;
            white-space: nowrap;
          }
        }
      }
    }    
  }
}

.table {
  min-width: 1124px;

  thead {
    // background: #EDEDEC;
    th {
      vertical-align: middle !important;
      font-size: 12px;
      font-weight: bold;
      // padding: .5rem;
      padding: 8px;
      text-align: center;
      line-height: 1.5;
      letter-spacing: -0.3px;
    }
    th.td_makerid {
      min-width: 68px;
    }
    th.td_maker {
      min-width: 100px;
    }
    th.td_type {
      min-width: 62px;
    }
    th.td_id {
      min-width: 68px;
    }
    th.td_project {
      min-width: 332px;
    }
    th.td_status {
      min-width: 68px;
    }
    th.td_startat {
      min-width: 68px;
    }
    th.td_endat {
      min-width: 68px;
    }
    th.td_wtp {
      min-width: 180px;
    }
    th.td_updater {
      min-width: 73px;
    }
    th.td_updated {
      min-width: 68px;
    }
  }
  tbody {
    border-bottom: solid 1px #dee2e6;
    tr {
      background-color: unset !important;
      cursor: pointer;
    }
    td {
      font-family: 'Noto Sans KR';
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: #44484b;
      vertical-align: middle !important;
      text-align: center;
      padding: 8px;

      span.date_for {
        font-family: 'Noto Sans KR';
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #44484b;
        display: flex;
        flex-direction: column;

        em {
          color: rgba(68, 72, 75, 0.5);
        }
      }

      span.ellipse {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 19vw;
        display: inline-block;
      }
    }
    td.left {
      text-align: left;
    }
    td.index {
      span {
        font-family: 'Noto Sans KR';
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #44484b;
        vertical-align: middle !important;
        text-align: center;
        padding-left: 3px;
      }
      div.rect_back {
        width: 40px;
        height: 10px;
        opacity: 0.65;
        border-radius: 2px;
        background-color: #dadce0;

        span.low {
          width: 8px;
          height: 10px;
          border-radius: 2px;
          background-color: #ff6666;
          display: block;
        }
        span.mid {
          width: 21px;
          height: 10px;
          border-radius: 2px;
          background-color: #ffb500;
          display: block;
        }
        span.high {
          width: 40px;
          height: 10px;
          border-radius: 2px;
          background-color: #00c4c4;
          display: block;
        }
      }
    }
    td.twoLine {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
      height: 44px;
    }
  }
  .multiline {
    white-space: pre
  }
}
