#main {
  #content_wrap {
      display: flex;
      min-height: calc(100vh - 56px);

      #contentMain {
          padding: 24px 48px 42px 48px;
          display: flex;
          flex-direction: column;
          min-width: 1061px;
          width: 85%;
      }
  }
}
