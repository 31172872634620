.title_wrapper {
  display: flex;
  flex-direction: column;
  min-width: 1124px;
  margin-bottom: 20px;

  .left {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    .page_title{
      margin-bottom: 0px;
      font-size: 28px;
      font-weight: bold;
      margin-right: 20px;
    }

    .button {
      width: 88px;
      height: 36px;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.4;
    }
  }

  .right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .latest_wrapper {
      margin-top: 20px;
    }

    .page_title{
      margin-bottom: 0px;
      font-size: 18px;
      font-weight: bold;
      margin-right: 20px;
    }

    .bold {
      font-family: 'Noto Sans KR';
      font-size: 13px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.84);
    }

    .normal {
      font-family: 'Noto Sans KR';
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.84);
    }

    .input_right {
      border: solid 1px rgba(0, 0, 0, 0.15);
      background-color: #ffffff;
      width: 32px;
      font-family: 'Noto Sans KR';
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: #1d2129;
      text-align: center;
    }

    span.date_for {
      font-family: 'Noto Sans KR';
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.84);

      em {
        color: rgba(68, 72, 75, 0.5);
      }
    }
  }
}

.row {
  span.title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    display: block;
  }
}

.table {
  min-width: 1124px;
  margin-bottom: 30px !important;

  thead {
    // background: #EDEDEC;
    th {
      vertical-align: middle !important;
      opacity: 0.9;
      font-family: 'Noto Sans KR';
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: -0.3px;
      text-align: center;
      color: rgba(0, 0, 0, 0.84);
      height: 56px;
    }
    th.td_index {
      min-width: 110px;
    }
    th.td_type {
      min-width: 80px;
    }
    th.td_source {
      min-width: 80px;
    }
    th.td_data {
      min-width: 260px;
    }
    th.td_number {
      min-width: 106px;
    }
    th.td_weight {
      min-width: 80px;
    }
    th.td_thresholda0 {
      min-width: 80px;
    }
    th.td_thresholda1 {
      min-width: 80px;
    }
    th.td_updater {
      min-width: 119px;
    }
    th.td_updated {
      min-width: 107px;
    }
    th.td_enabled {
      min-width: 95px;
    }
  }
  tbody {
    border-bottom: solid 1px #dee2e6;

    tr {
      background-color: unset !important;
    }

    td {
      opacity: 0.9;
      font-family: 'Noto Sans KR';
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #44484b;
      vertical-align: middle !important;
      text-align: center;
      height: 52px;

      span.index_kor {
        opacity: 0.9;
        font-family: 'Noto Sans KR';
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: center;
        color: rgba(0, 0, 0, 0.84);
        display: flex;
        flex-direction: column;

        em {
          opacity: 0.9;
          font-family: 'Roboto';
          font-size: 10px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: center;
          color: #90949c;
        }
      }

      span.date_for {
        opacity: 0.9;
        font-family: 'Noto Sans KR';
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #44484b;
        display: flex;
        flex-direction: column;

        em {
          color: rgba(68, 72, 75, 0.5);
        }
      }

      input {
        background-color: #ffffff;
        font-family: 'Noto Sans KR';
        font-size: 13px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        color: #1d2129;
        margin: 0 auto;
        text-align: center;
        border: none;
      }

      input.items {
        width: 50px;
      }

      .span_weight {
        white-space: nowrap;

        input {
          width: 30px;
        }
      }

      .termType {
        font-size: 12px;
        padding: 0 10px 0 0px !important;
      }
    }

    td.left {
      text-align: left;
    }
    td.toggle {
      justify-content: center;
      display: flex;

      div {
        text-align: left;
      }
    }

    td.rep {
      opacity: 0.9;
      background-color: #fafbfc;
      border-right: 1px solid #ccced2;
    }
  }
  .multiline {
    white-space: pre
  }
}
