.table {
  thead {
    tr {
      th {
        min-width: 56px;
        font-family: 'Noto Sans KR';
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.3px;
        text-align: center;
        color: rgba(0, 0, 0, 0.84);
        padding: 8px;
        vertical-align: middle;
      }
      th.top_thead {
        background-color: #fafbfc;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
      }
      th.left_border {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
      }
      th.top_thead_date {
        min-width: 96px;
      }
      th.latest {
        min-width: 88px;
        background-color: white;
      }
    }
  }

  tbody {
    tr {
      td {
        min-width: 56px;
        font-family: 'Noto Sans KR';
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #44484b;
        padding: 16px 8px;
        vertical-align: middle;
        word-break: break-all;

        span.date_for {
          font-family: 'Noto Sans KR';
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: normal;
          text-align: center;
          color: #44484b;
          display: flex;
          flex-direction: column;
  
          em {
            color: rgba(68, 72, 75, 0.5);
          }
        }
      }

      td.left_border {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
      }

      td.date {
        width: 70px;
      }


    }

    tr.highlight {
      background-color: yellow !important;
    }
  }
}