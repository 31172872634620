#company_info {
  .row1 {
    margin-top: 40px;
    margin-bottom: 40px;
    
    span.title {
      font-family: 'Noto Sans KR';
      font-size: 17px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.65;
      letter-spacing: normal;
      color: #1d2129;
      margin-bottom: 26px;
      display: block;
    }
    
    .info_wrapper {
      display: grid;
      grid-template-columns: 50% 50%;
    }
    
    .info_item {
      box-shadow: inset 0 -1px 0 0 #e6eaed;
      position: relative;
      width: 97%;
      height: 44px;
      display: flex;
      align-items: center;
      
      span.info_title {
        display: inline-block;
        width: 120px;
        font-family: 'Noto Sans KR';
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.84);
      }
      
      span.info_content {
        font-family: 'Noto Sans KR';
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: #90949c;
        display: inline-block;
        width: 469px;
      }
    }
    
    .right {
      left: 3%;
    }
  }
  
  .row2 {
    margin-top: 40px;
    margin-bottom: 40px;
    
    span.title {
      font-family: 'Noto Sans KR';
      font-size: 17px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.65;
      letter-spacing: normal;
      color: #1d2129;
      margin-bottom: 16px;
      display: block;
    }
    
    .more_info_wrapper {
      border: solid 1px #dadce0;
      background-color: #fafbfc;
      padding: 16px 16px 24px 16px;
      
      p {
        margin: 0;
      }
      
      .sub_title {
        font-family: 'Noto Sans KR';
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.84);
        display: block;
        margin-bottom: 8px;
      }
      
      .item {
        font-family: 'Noto Sans KR';
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.6;
        letter-spacing: normal;
        color: #90949c;
      }

      .link {
        color: #00a2a2;
        text-decoration: underline;
      }
    }
  }
}