.page_location{
  margin-bottom: 40px;
  &:after{
    display: block;
    clear: both;
    content: '';
  }
  li{
    float: left;
    font-size: 13px;
    color: #9b9fa6;
    &.active{
      color: #20252f;
    }
    i{
      margin: 0px 3px;
      color: #9b9fa6;
    }
  }
}
