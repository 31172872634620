.tooltip_box{
    position: relative;
    display: inline-block;
    margin: 0 4px;
    .tooltip_btn{
        font-size: 14px;
        cursor: pointer;
        img {
            padding-bottom: 2px;
        }
    }
    div {
        top: 30px;
        right: auto;
        transform: none;
        border-radius: unset;
    }
}