#leftMenu {
  padding: 38px 15px 0px 24px;
  // min-height: calc(100vh - 57px);
  // height: 100%;
  flex: 0 0 220px;
  top: 0;
  width: 220px;
  height: inherit;
  background-color: #60656a;

  .menu_depth1 {
      margin-bottom: 28px;

      .menu_depth1_title {
          position: relative;
          width: 100%;
          text-align: left;
          white-space: pre-line;

          &.arrow {
              &:after {
                  position: absolute;
                  display: inline-block;
                  content: "\E94E";
                  top: 50%;
                  right: 20px;
                  color: #fff;
                  font-size: 16px;
                  font-family: wadizicon !important;
                  transform: translateY(-50%);
              }
              &.expand:after { content: "\E94D"; }

              & + .menu_depth2 {
                  height: 0;
                  overflow: hidden;
              }
              &.expand + .menu_depth2 { height: auto; }
          }

          & > span {
              color: rgba(255, 255, 255, 0.84);
              font-size: 15px;
              font-weight: bold;
              line-height: 18px;
              text-align: left;

              &.active { color: #00c4c4; }
          }
      }

      .menu_depth2_title {
          margin-top: 28px;
          display: block;
          width: 100%;
          color: rgba(255, 255, 255, 0.54);
          font-size: 15px;
          font-weight: normal;
          line-height: 20px;
          text-align: left;
          text-decoration: none;
          white-space: pre-line;

          & > a {
              font-size: 15px;
              color: rgba(255, 255, 255, 0.54);
          }

          & > a.active {
              color: #00c4c4 !important;
              font-weight: bold;
          }

          & > button {
              height: auto;
              color: rgba(255, 255, 255, 0.54);
          }
      }
      .menu_depth3_title {
          margin-top: 15px;
          display: block;
          width: 100%;
          color: rgba(255, 255, 255, 0.54);
          font-size: 15px;
          font-weight: normal;
          line-height: 20px;
          text-align: left;
          text-decoration: none;
          white-space: pre-line;
          padding-left: 40px;

          & > a {
              font-size: 15px;
              color: rgba(255, 255, 255, 0.54);
              width: 70%;
              display: inline-block;
          }

          & > a.active {
              color: #00c4c4 !important;
              font-weight: bold;
          }

          & > button {
              height: auto;
              color: rgba(255, 255, 255, 0.54);
          }
      }
  }
}
  // .admin_search_menu {
  //     .admin_search_menu_depth1 {
  //         margin: 0 60px 48px 0;
  //         padding-left: 48px;

  //         .admin_search_menu_depth1_title {
  //             color: #fff;
  //             font-size: 17px;
  //             line-height: 28px;
  //             text-decoration: none;

  //             &.active { font-weight: bold; }
  //         }
  //     }

  //     .admin_search_menu_depth2 {
  //         margin-top: 24px;

  //         .admin_search_menu_depth2_title {
  //             color: #fff;
  //             font-size: 17px;
  //             line-height: 28px;
  //             text-decoration: none;

  //             &.active {
  //                 color: #2ed5d5;
  //                 font-weight: bold;
  //             }
  //         }
  //     }
  // }
// }
