.shadow {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
    z-index: 999;

    .dialog_wrap {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 50%;
        left: 50%;
        padding: 36px 60px 90px 140px;
        min-width: 920px;
        height: auto;
        background-color: #fff;
        transform: translate(-50%, -50%);

        .dialog_close {
            flex: 0 0 24px;
            margin-bottom: 31px;
            width: 100%;
            height: 24px;
            text-align: right;

            i { cursor: pointer; }
        }

        .dialog_title {
            flex: 0 0 auto;
            margin-bottom: 16px;

            span {
                color: rgba(0,0,0,.84);
                font-size: 28px;
                line-height: 36px;
                font-weight: bold;
            }
        }

        .dialog_content {
            flex: 1 1 auto;
            margin-bottom: 40px;

            span {
                color: rgba(0,0,0,.84);
                font-size: 17px;
                line-height: 24px;
                font-weight: 300;
            }
        }

        .dialog_button {
            flex: 0 0 48px;
            padding-right: 56px;
            text-align: right;

            button:not(:first-child) { margin-left: 8px; }
        }
    }
}