
.toggle {
    input[type=checkbox] { display: none; }
    input[type=checkbox]:checked + span {
        background-color: #00b2b2;

        
        &:before {
            left: 16px;
        }
    }

    span {
        display: inline-block;
        width: 39px;
        height: 24px;
        border-radius: 18px;
        background-color: #dcdcdc;
        transition: background-color .3s;

        &:before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;

            border-radius: 50%;
            background-color: #ffffff;

            top: 2px;
            left: 4px;
            position: relative;

            transition: left .3s;
        }
    }
}