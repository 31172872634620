.wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 10px;

  .wrapper2 {
    display: flex;
    flex-direction: column;

    span {
      font-family: 'Noto Sans KR';
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.3px;
      color: rgba(0, 0, 0, 0.84);
      margin-bottom: 8px;
    }

    input[type=text] {
      height: 36px;
      width: 173px;
      margin-right: 16px;

      font-family: 'Noto Sans KR';
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.3);
    }
  }

  button.btn_change {
    height: 36px;
    width: 48px;
    font-family: 'Noto Sans KR';
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.46;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding: 0;
  }
}