#header {
  display: flex;
  padding: 0 24px 0 24px;
  height: 56px;
  border-bottom: 1px solid rgba(0,0,0,0.1);

  .left {
      display: flex;
      align-items: center;
      height: 100%;

      .logo {
          width: auto;
          height: 33px;
          object-fit: contain;
          margin-right: 16px;
      }

      .rectangle {
          width: 1px;
          height: 14px;
          background-color: #90949c;
      }

      .headername {
          margin-left: 16px;
          font-family: 'Noto Sans KR';
          font-size: 15px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: rgba(0, 0, 0, 0.84);
      }
  }

  .right {
      position: relative;
      display: flex;
      align-items: center;
      margin-left: auto;
      height: 100%;

      button.my_page {
          font-size: 15px;
          color: #60656a;
          margin: 0;
      }

      span {
          font-size: 15px;
          color: #60656a;
          margin-left: 16px;
      }
  }
}
