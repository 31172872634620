.pagination {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-start;

  li {
    padding: 0 13px;
    font-size: 15px;

    .pagination_arrow {
      &.active {
          color: #00b28f;
      }
    }

    .pagination_item {
      font-size: 15px;
      color: rgba(0, 0, 0, 0.54);

      &.active {
        color: #00bf99;
      }
    }
  }
}
