.title_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 1124px;
  align-items: center;

  .left {
    // 페이지 제목
    .page_title{
      margin-bottom: 28px;
      font-size: 28px;
      font-weight: bold;
    }
  }
}

.filter_wrapper {
  width: 1124px;
  height: 196px;
  border: solid 8px #f0f2f5;
  background-color: #ffffff;
  margin-bottom: 40px;

  display: grid;
  grid-template-columns: 50% 50%;

  .date_wrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;
    label.title {
      font-family: 'Noto Sans KR';
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.2px;
      color: rgba(0, 0, 0, 0.84);
      margin-left: 32px;
      margin-right: 26px;
      margin-bottom: 0px;
    }
    .date_range_input_wrap {
      display: flex;
      align-items: center;

      .range_ico {
        padding: 0px 8px;
        font-size: 18px;
      }

      // input[type=date]::-webkit-calendar-picker-indicator,
      input[type=date]::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
      }
    }
  }

  .type_wrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;
    label.title {
      font-family: 'Noto Sans KR';
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.2px;
      color: rgba(0, 0, 0, 0.84);
      margin-left: 32px;
      margin-right: 26px;
      margin-bottom: 0px;
    }
    .checkbox_wrap {
      display: flex;
      label {
        margin: 0 15px 0 0;
        width: auto !important;

        span {
          font-family: 'Noto Sans KR';
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.45;
          letter-spacing: -0.2px;
          color: rgba(0, 0, 0, 0.84);
        }
      }
    }
  }

  .detail_wrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;
    label.title {
      font-family: 'Noto Sans KR';
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.2px;
      color: rgba(0, 0, 0, 0.84);
      margin-left: 32px;
      margin-right: 26px;
      margin-bottom: 0px;
    }
    div > input {
      margin-left: 26px;
      width: 265px;
      font-family: 'Noto Sans KR';
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
    }
  }

  .status_wrapper {
    display: flex;
    align-items: center;
    margin-right: 20px;
    label.title {
      font-family: 'Noto Sans KR';
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: -0.2px;
      color: rgba(0, 0, 0, 0.84);
      margin-left: 32px;
      margin-right: 26px;
      margin-bottom: 0px;
    }
    .checkbox_wrap {
      display: flex;
      label {
        margin: 0 15px 0 0;
        width: auto !important;

        span {
          font-family: 'Noto Sans KR';
          font-size: 15px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.45;
          letter-spacing: -0.2px;
          color: rgba(0, 0, 0, 0.84);
        }
      }
    }
  }

  .btn_wrapper {
    display: flex;
    justify-content: flex-end;
    margin-right: 32px;
    button {
      width: 88px;
      height: 36px;
      font-family: 'Noto Sans KR';
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      // line-height: 2.4;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
    }
  }
}

.view_wrapper {
  display: flex;
  justify-content: flex-end;
  .right {
    .filter_row{
      display: flex;
      align-items: center;
      margin-bottom: 17px;
      label{
        width: 80px;
        margin-right: 8px;
        margin-bottom: 3px;
        font-size: 13px;
        font-weight: bold;
      }
    }

    .search_box{
      justify-content: flex-end;
      .seach_input_wrap{
        display: flex;
        align-items: center;
        .select_box{
          margin-right: 0px;
          select{
            padding: 1px 32px 1px 12px;
          }
        }
        .input_box{
          margin-right: 8px;
        }
      }
    }
    
    .approval_checkbox{
      padding: 7px 0;
      .approval_checkbox_wrap{
        display: flex;
        align-items: center;
        label{
          width: 80px;
        }
        span{
          font-weight: normal;
        }
      }
    }
    
    .advertiser_checkbox{
      display: flex;
      padding: 7px 0;
      margin-right: 0px;
      .float_box{
        display: flex;
        &:first-child{
          margin-right: 116px;
        }
        .checkbox_wrap{
          display: flex;
          label{
            width: 80px;
            &.width_item{
              width: 110px;
            }
          }
    
          span{
            font-weight: normal;
            width: unset;
            word-break: break-all;
            white-space: nowrap;
          }
        }
      }
    }    
  }
}

div.table_wrapper {
  overflow-x: auto;
  min-width: 1124px;
}

.table {
  min-width: 1124px;
  // border: none;
  border-top: 1px solid #ccced2;

  thead {
    border: none;
    th {
      vertical-align: middle !important;
      opacity: 0.9;
      font-family: 'Noto Sans KR';
      font-size: 10px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.45px;
      text-align: center;
      padding: 4px;
      color: rgba(0, 0, 0, 0.84);;
      min-width: 26px;
      border-right: 1px solid #e4e4e4;
      border-bottom: 1px solid #ccced2 !important;
      opacity: 0.9;
      background-color: #fafbfc;
    }
    th.td_updated {
      min-width: 70px;
      border-right: 1px solid #b7b7b7;
    }
    th.td_cred {
      min-width: 430px;
      border-right: 1px solid #b7b7b7;
    }
    th.td_inter {
      min-width: 860px;
      border-right: 1px solid #b7b7b7;
    }
    th.td_attr {
      min-width: 516px;
      border-right: 1px solid #b7b7b7;
    }
    th.td_update {
      min-width: 34px;
      border-left: 1px solid #b7b7b7;
    }
    th.td_latest {
      min-width: 52px;
    }
    th.td_item {
      border-right: none;
    }
    th.right_border {
      border-right: 1px solid #b7b7b7;
    }
  }
  tbody {
    border-bottom: solid 1px #dee2e6;
    tr {
      background-color: unset !important;
    }
    td {
      font-family: 'Noto Sans KR';
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.2px;;
      letter-spacing: normal;
      text-align: center;
      color: #44484b;
      vertical-align: middle !important;
      text-align: center;

      span.date_for {
        opacity: 0.9;
        font-family: 'Noto Sans KR';
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: center;
        color: #44484b;
        display: flex;
        flex-direction: column;

        em {
          color: rgba(68, 72, 75, 0.5);
        }
      }
    }
    td.left {
      text-align: left;
    }

    td.diff {
      color: red;
      font-weight: bold;
    }
  }
  .multiline {
    white-space: pre
  }
}
